import axios from './config/axios_config'
function getUserLogin(params) {
    return axios({
        url:'/login',
        method:"get",
        params
    })
}
function addUserInfo(data) {
    return axios({
        url:'/liveUser/addUser',
        method:"post",
        data
    })
}
function getAccountInfo(params) {
    return axios({
        url:'/liveUser/selectUser',
        method:"get",
        params
    })
}
function deleteUserInfo(data) {
    return axios({
        url:'/liveUser/deleteUser',
        method:"post",
        data
    })
}
function updateUserInfo(data) {
    return axios({
        url:'/liveUser/updateUser',
        method:"post",
        data
    })
}
//获取直播全部信息
function getLiveInfo(params){
    return axios({
        url:"/liveInfo/selectLiveInfo",
        method:"get",
        params
    })
}
//获取单个直播信息
function getLiveSingleInfo(params){
    return axios({
        url:"/liveInfo/getLiveInfo",
        method:"get",
        params
    })
}
export default{
    getUserLogin,
    addUserInfo,
    getAccountInfo,
    deleteUserInfo,
    updateUserInfo,
    getLiveInfo,
    getLiveSingleInfo
}