import axios from "axios"
import apiConfig from './api_config'
export default function axiosService(options){
    //创建实例
    return new Promise((resolve,reject)=>{
        const axiosService = axios.create({
            baseURL:apiConfig.baseURL,
            headers:apiConfig.headers,
            withCredentials:apiConfig.withCredentials //解决cookie时，跨域问题
        })
        //请求拦截
        axiosService.interceptors.request.use(
            config=>{
                const adminToken = localStorage.token
                if (adminToken) {
                    // config.headers['Authorization'] = adminToken
                    config.headers={
                        'token':adminToken
                    }
                }
                return config
            },
            err=>{
                // this.$message.error('请求超时！')
                return Promise.reject(err)
            }
        )
        //响应拦截
        axiosService.interceptors.response.use(
            response=>{
                //根据自己的响应结果的数据结构进行判断
                if(response.status!=200){
                    // this.$message.error(response.msg)
                    return
                }else{
                    if(response.data.errorCode==4){
                        window.location.href='login'
                    }
                    return Promise.resolve(response)
                }
            },
            err=>{
                if(!err.response){
                    return Promise.reject(err) //返回接口的错误信息
                }
                //根据后端的状态码进行判断
                switch(err.response.status){
                    case 402:
                        err.message = '登录失败';
                        //跳转到登录
                        //window.location.href='login'
                        break;
                    case 403:
                        err.message = '权限不足';
                        break;
                    case 20002:
                        err.message = '服务器撸猫去了~~';
                        break;
                    case 20004:
                        err.message = '业务异常';
                        break;
                    case 4:
                        err.message = '用户未登录，无法访问保护资源';
                        break;
                    case 0:
                        err.message = '查询结果为空';
                        break;
                }
                return Promise.reject(err);//返回接口错误信息
            }
        )
        //调用请求处理方法
        axiosService(options).then(res=>{
            resolve(res)
            return false
        }).catch(err=>{
            reject(err)
        })
    })
}