import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   {
    path: '/',
    redirect: '/message'
  },
  // {
  //   path: '*',
  //   redirect: '/message'
  // },
  // {
  //   path:'/index',
  //   name:'Index',
  //   component:()=>import ('@/views/home/index.vue'),
  //   children:[
  //     {path:'',component:Account},
  //     {path:'/account',name:'Account',component:Account},
  //     {path:'/liveList',name:'LiveList',component:()=>import('@/views/liveList/live-list.vue')},
  //     // {path:'/historyLive',name:'HistoryLive',component:()=>import('@/views/historyLive/history-live.vue')},
  //   ]
  // },
  // {
  //   path:'/login',
  //   name:'Login',
  //   component:()=>import ('@/views/login.vue')
  // },
  {
    path:'/message',
    name:'Message',
    component:()=>import ('@/views/message/message.vue')
  },
  {
    path:'/messageDetail',
    name:'message-detail',
    component:()=>import ('@/views/message/messageDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//路由守卫（没有token时只能跳转到登录页和注册页）

// router.beforeEach((to, from, next) => {
//   const isLogin = localStorage.token ? true : false
//   if (to.path == "/login" || to.path == "/register") {
//     next()
//   } else {
//     isLogin ? next() : next('/login')
//   }
// })

export default router
